@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

$body-bg: #f1f0ec;
$body-color: #050516;

$theme-colors: (
    "site-primary": #4b4994,
    "site-orange": #fe5129,
    "site-info": #48cae4,
    "site-heading": #03223f,
    "site-secondary": #52b3f6,
    "site-light-heading": #f1f0ec,
    "site-main-color": #d6d5d2,
);

@import "node_modules/bootstrap/scss/bootstrap";

.pointer {
    cursor: pointer !important;
}
html {
    scroll-behavior: smooth;
}
body {
    background-color: #ffffff;
}
.fontText {
    font-family: "Montserrat";
}

// .is-invalid ~ .invalid-feedback.fontText {
//     color: #dc3545 !important;
// }
// .invalid-feedback.fontText {
//     color: #000;
//     font-size: 100%;
// }
.setLink:hover {
    text-decoration: none !important;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    //   border: none !important;
    border-color: #ced4da;
    background-image: none !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 254, 0.25);
    //   box-shadow: none;
}
.form-control:required:is-invalid {
    //   border: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    box-shadow: #808080 !important;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.tooltip-inner {
    background: #ced4da !important;
    padding: 10px !important;
    font-weight: 500 !important;
    color: black !important;
    min-width: 300px !important;
    text-align: left !important;
}
.tooltip .arrow::before {
    border-right-color: #ced4da !important;
}
.form-control:focus {
    box-shadow: none !important;
}
.HeaderMain {
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    @media (max-width: 600px) {
        flex-direction: column;
    }
    .logoText {
        font-size: 19px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 800;
        @media (max-width: 600px) {
            font-size: 17px;
        }
    }
    .logoText1 {
        font-size: 16px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 300;
        @media (max-width: 600px) {
            font-size: 15px;
        }
    }
    .logoText2 {
        font-size: 18px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 800;
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    .headerRightText {
        font-size: 20px;
        color: #ffffff;
        font-weight: 700;
        font-family: "Montserrat";
        @media (max-width: 600px) {
            text-align: center;
            font-size: 18px;
        }
    }
}
.topSection {
    .bgImg {
        background: url("../../assets/img/bg2.png");
        background-repeat: no-repeat;
        background-size: 100% 165%;
        @media (max-width: 1023px) {
            // background-image: linear-gradient(45deg, #44bfff 0%, #4777f3 100%);
            background: url("../../assets/img/mobilebg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }

    .FormTextSection {
        .headingText1 {
            font-size: 30px;
            color: #ffffff;
            font-weight: 700;
            line-height: 45px;
            font-family: "Montserrat";
            @media (max-width: 424px) {
                text-align: center;
                font-size: 22px;
                line-height: 30px;
                font-weight: 600;
            }
            @media (max-width: 800px) and (min-width: 425px) {
                font-size: 25px;
                text-align: center;
                line-height: 30px;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                font-size: 25px;
            }
        }
        .headingText2 {
            font-size: 23px;
            line-height: 40px;
            color: #ffffff;
            font-weight: 700;
            font-family: "Montserrat";
            @media (max-width: 424px) {
                text-align: center;
                font-size: 18px;
                line-height: 26px;
                font-weight: 600;
            }
            @media (max-width: 800px) and (min-width: 425px) {
                font-size: 20px;
                text-align: center;
                line-height: 26px;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                font-size: 19px;
                line-height: 30px;
            }
        }
        .headingText3 {
            font-size: 24px;
            color: #ffffff;
            font-weight: 300;
            line-height: 40px;
            font-family: "Montserrat";
            @media (max-width: 424px) {
                text-align: center;
                font-size: 20px;
                line-height: 30px;
                margin-top: 2px;
            }
            @media (max-width: 800px) and (min-width: 425px) {
                font-size: 21px;
                text-align: center;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                // margin-top: 200px;
                font-size: 20px;
            }
        }
        .formBtnOuter {
            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
            }
        }
        .formBtn {
            border-radius: 3px;
            border: none !important;
            background-color: #ffc007;
            font-size: 25px;
            line-height: 52px;
            color: #ffffff;
            font-weight: 700;
            font-family: "Montserrat";
            padding: 5px 15px;
            text-align: center;
            @media (max-width: 374px) {
                font-size: 23px;
                line-height: 31px;
                padding: 5px 10px;
            }
            @media (max-width: 600px) and (min-width: 375px) {
                font-size: 23px;
                line-height: 50px;
                padding: 5px 10px;
            }
        }
        .headingText4 {
            font-size: 14px;
            /* line-height: 29px; */
            color: #000000;
            font-weight: 500;
            font-family: "Montserrat";
            @media (max-width: 991px) {
                text-align: center;
                color: white;
            }
        }
        .belowSpace {
            height: 60px;
            @media (max-width: 991px) {
                display: none;
            }
            @media (max-width: 1090px) and (min-width: 1024px) {
                height: 82px;
            }
        }
        .space {
            margin-top: 200px;
        }
        .mainCircle {
            // position: relative;
            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }
        }
        .space143 {
            margin-top: 135px;
            @media (max-width: 991px) {
                margin-top: 0px;
            }
        }
        .arrowSet {
            // position: absolute;
            // left: -12px;
            margin-left: -20px;
            @media (max-width: 768px) {
                left: 0px;
                position: relative;
                margin-top: 0px;
                align-items: center;
                text-align: center;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                margin-top: 125px;
            }
        }
        .circle1 {
            padding: 11px 15px;
            opacity: 0.91;
            // margin-top: 225px;
            border-radius: 64px;
            margin-left: -5px;
            margin-top: 11px;
            color: #262626;
            // position: absolute;
            // left: 0px;
            width: 315px;
            filter: drop-shadow(0.866px -0.5px 10.5px rgba(0, 0, 0, 0.13));
            background-color: #f9f9fb;
            @media (max-width: 768px) {
                position: relative;
                margin-top: 10px;
                text-align: center;
                margin-top: 5px;
            }
            @media (max-width: 600px) {
                font-size: 15px;
                width: 100%;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                // margin-top: 200px;
            }

            .shadowText1 {
                color: #7370e2;
            }
        }
        .textcircle {
            font-size: 22px;
            line-height: 32px;
            color: #262626;
            font-weight: 700;
            font-family: "Montserrat";
            @media (max-width: 600px) {
                font-size: 18px;
            }
        }
        .circle2 {
            padding: 11px 15px;
            opacity: 0.91;
            border-radius: 64px;
            width: 100%;
            position: absolute;
            right: 225px;
            bottom: 57px;
            filter: drop-shadow(0.866px -0.5px 10.5px rgba(0, 0, 0, 0.13));
            background-color: #f9f9fb;
            @media (max-width: 600px) {
                width: 100%;
                font-size: 16px;
            }
            @media (max-width: 999px) {
                right: 0px;
                bottom: 0px;
                position: relative;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                right: 200px;
            }
            @media (max-width: 1090px) and (min-width: 1024px) {
                bottom: 34px;
            }
            @media (max-width: 1199px) and (min-width: 1091px) {
                bottom: 15px;
            }
            .shadowText2 {
                color: #ffc007;
            }
        }
    }
    .midText {
        font-size: 28px;
        line-height: 31px;
        color: #2c2c2c;
        font-weight: 700;
        font-family: "Montserrat";
        text-align: center;
        @media (max-width: 375px) {
            font-size: 23px;
            line-height: 27px;
        }
        @media (max-width: 600px) and (min-width: 376px) {
            font-size: 25px;
            line-height: 27px;
        }
    }
    .bgCardOuter {
        padding: 30px 0px;
        @media (max-width: 600px) {
            padding: 15px 0px;
        }
        .bgCard {
            // width: 190px;
            height: 100%;
            filter: drop-shadow(0 0 25px rgba(37, 36, 36, 0.05));
            background-color: #ffffff;
            border-bottom: 2px solid #44bfff;
            margin-bottom: 10px;
            .cardText {
                font-size: 17px;
                color: #222222;
                font-weight: 700;
                word-break: break-word;
                // flex-wrap: wrap;
                font-family: "Montserrat";
                text-align: center;
            }
        }
    }
    .thirdsectionOuter {
        background-color: rgba(67, 185, 246, 0.2);
        .imgTextHeading {
            font-size: 32px;
            line-height: 26px;
            color: #000000;
            font-weight: 700;
            font-family: "Montserrat";
            @media (max-width: 375px) {
                text-align: center;
                font-size: 23px;
                margin-bottom: 0px;
            }
            @media (max-width: 600px) and (min-width: 376px) {
                margin-bottom: 0px;
                text-align: center;
                font-size: 25px;
            }
        }
        .imgTextDesc {
            font-size: 18px;
            line-height: 26px;
            color: #000000;
            font-weight: 400;
            text-align: justify;
            font-family: "Montserrat";
            @media (max-width: 600px) {
                text-align: center;
            }
        }
    }
    .fourthSection {
        background-image: linear-gradient(45deg, #44bfff 0%, #063970 100%);
        padding-top: 70px;
        padding-bottom: 70px;
        @media (max-width: 600px) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .btnText {
            font-size: 45px;
            line-height: 60px;
            color: #ffffff;
            font-weight: 700;
            font-family: "Montserrat";
            text-align: center;
            @media (max-width: 375px) {
                font-size: 23px;
                line-height: 40px;
            }
            @media (max-width: 780px) and (min-width: 376px) {
                font-size: 25px;
                line-height: 45px;
            }
            @media (max-width: 1199px) and (min-width: 1000px) {
                font-size: 32px;
            }
        }
        .btnSet {
            border-radius: 3px;
            background-color: #ffc007;
            font-size: 25px;
            line-height: 52px;
            color: #ffffff;
            font-weight: 700;
            font-family: "Montserrat";
            padding: 5px 15px;
            text-align: center;
            color: #ffffff;
            display: block;
            margin: auto;
            font-weight: 700;
            border: none;
            text-decoration: none;

            @media (max-width: 600px) {
                font-size: 26px;
                line-height: 31px;
                padding: 10px 10px;
            }
        }
    }
    .footer__text {
        font-size: 14px;
        line-height: 23px;
        color: #2c2c2c;
        font-weight: 700;
        font-family: "Montserrat";
        text-align: center;
    }
    .footer__link {
        color: black;
        font-family: "Montserrat";
        font-size: 15px;
        font-weight: 450;
        text-align: center;

        @media (max-width: 374px) {
            font-size: 13px;
            text-align: center;
        }
    }
}
.formOuter {
    background: url("../../assets/img/bg2.png");
    background-repeat: no-repeat;
    background-size: 100% 850px;
    @media (max-width: 780px) {
        background-image: linear-gradient(45deg, #44bfff 0%, #4777f3 100%);
        background-size: cover;
    }
    .formHeading {
        font-size: 40px;
        line-height: 53px;
        color: #fefefe;
        font-weight: 700;
        font-family: "Montserrat";
        text-align: center;
    }
    .mainForm {
        // width: 748px;
        width: 964px;
        filter: drop-shadow(0 0 25px rgba(34, 34, 34, 0.05));
        background-color: #ffffff;
        border-bottom: 2px solid #44bfff;

        @media (max-width: 991px) {
            width: 100%;
        }
        @media (max-width: 600px) {
            .mobileView {
                flex-direction: column;
                text-align: center;
            }
        }
        Input:focus {
            box-shadow: none;
        }
        .selectText {
            font-size: 1rem !important;
            height: 50.21px;
            color: #495057;
            &:focus {
                box-shadow: none;
            }
        }
        .headingText {
            font-size: 16px;
            // line-height: 60px;
            // color: #5395f1;
            color: #000000;
            font-weight: 400;
            font-family: "Montserrat";
            text-align: center;
            // cursor: pointer;
        }
        .activeTab {
            color: #5395f1;
            font-weight: 700;
        }
        .borderStyling {
            height: 1px;
        }
        .formBodyHeading {
            // font-size: 24px;
            color: #000000;
            font-weight: 700;
            font-family: "Montserrat";
            @media (max-width: 600px) {
                text-align: center;
            }
        }
        .radioHeading {
            font-weight: 700;
            font-family: "Montserrat";
            font-size: 16px;
        }
        .radioBtn {
        }
        .radioText {
            font-weight: 500;
            font-family: "Montserrat";
        }
        .hrStyling {
            border-top: 2px solid rgba(0, 0, 0, 0.1);
        }
        .formBodyText {
            font-size: 16px;
            line-height: 25px;
            color: #000000;
            font-weight: 400;
            font-family: "Montserrat";
            @media (max-width: 600px) {
                text-align: center;
            }
        }
        .formHeading2 {
            // font-size: 16px;
            line-height: 40px;
            color: #000000;
            font-weight: 700;
            font-family: "Montserrat";
            // @media (max-width: 600px) {
            //     text-align: center;
            // }
        }
        .btnText {
            font-size: 18px;
            color: #ffffff;
            font-weight: 600;
            font-family: "Montserrat";
            margin: 0px;
            padding: 0px;
            @media (max-width: 900px) {
                font-size: 16px;
            }
        }
        .btnHeight {
            min-height: 68px;
            border: none !important;
        }
        .detailBox {
            border: 1px solid #ced4da;
            word-break: break-word;
            width: 100%;
            min-height: 100px;
        }
        .boxTextBtm {
            font-size: 12px;
            font-style: italic;
        }
        .formCircle {
            padding: 5px 10px;
            opacity: 0.91;
            border-radius: 64px;
            width: 415px;
            position: absolute;
            font-size: 22px;
            color: #262626;
            left: 60px;
            font-weight: 700;
            font-family: "Montserrat";
            filter: drop-shadow(0.866px -0.5px 10.5px rgba(0, 0, 0, 0.13));
            background-color: #f9f9fb;
            @media (max-width: 600px) {
                position: relative;
                width: 100%;
                left: 0px;
                font-size: 15px;
            }
            @media (max-width: 900px) and (min-width: 601px) {
                position: relative;
                width: 320px;
                left: 0px;
            }
        }

        .box {
            width: 100%;
            background: #ced4da;
            padding: 20px 0px 20px 5px;
            font-weight: 500;
            font-size: 13px;
            margin-top: 95px;
            position: relative;
            @media (max-width: 600px) {
                margin-top: 0px;
            }
        }
        .box2 {
            background: #ced4da;
            padding: 15px;
            font-weight: 500;
            font-size: 14px;
            position: relative;
            border-radius: 4px;
            @media (max-width: 600px) {
                margin-top: 10px;
            }
        }
        .mobileFont {
            font-weight: 700;
            @media (max-width: 600px) {
                font-size: 2rem;
            }
        }
        .sb2:before {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: -11px;
            width: 0;
            height: 0;
            border-top: 11px solid transparent;
            border-bottom: 11px solid transparent;
            border-right: 14px solid #ced4da;
        }
        .wozInput {
            position: relative;
            .euroSign {
                position: absolute;
                left: 10px;
                top: 17.5px;
            }
        }
        .fa-question-circle:before {
            color: #777575;
        }

        .fa-exclamation-circle:before {
            color: #777575;
        }
        .aboutText {
            height: 300px;
            border: 1px solid black;
            margin-top: 10px;
            resize: none;
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.thankYouMain {
    background: url("../../assets/img/bg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 700px;
    @media (max-width: 780px) {
        background: url("../../assets/img/mobilebg.png");
        // background-image: linear-gradient(45deg, #44bfff 0%, #4777f3 100%);
        min-height: auto !important;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .thanksText {
        font-size: 40px;
        line-height: 53px;
        color: #fefefe;
        font-weight: 700;
        font-family: "Montserrat";
        text-align: center;
    }
}

// SignaturePAd Styling

#signature-pad {
    canvas {
        width: 100% !important;
        height: 350px !important;
        border: 1px solid black !important;
        margin-top: 10px !important;
    }
}
